import React from "react"
import styled from "styled-components"
import pattern from "../images/pattern.jpg"
import DesignTokens from "./designTokens"
import { Container, ItemsList } from "./utils"
import Heading from "./heading"

const TextWithAsset = ({ section, headingShadow, headingDowncase, even }) => {
  return (
    <Wrapper>
      <CustomContainer even={even}>
        {section.image && (
          <img src={section.image.url} alt={section.image.alt} />
        )}
        <div>
          {section.title && (
            <Heading as="h3" shadow={headingShadow} downcase={headingDowncase}>
              {section.title}
            </Heading>
          )}
          <div dangerouslySetInnerHTML={{ __html: section.text }} />
          <Assets assets={section.assets} />
        </div>
      </CustomContainer>
    </Wrapper>
  )
}

const Assets = ({ assets }) => (
  <Certifications>
    {assets.map(asset => (
      <li key={asset.originalId}>
        <a href={asset.url} target="_blank" rel="noopener noreferrer">
          {asset.title}
        </a>
      </li>
    ))}
  </Certifications>
)

const Certifications = styled(ItemsList)`
  margin-top: ${DesignTokens.spacing[4]};
  a {
    color: ${DesignTokens.colors.primary[500]};
    &:hover {
      color: ${DesignTokens.colors.black};
    }
  }
`

const Wrapper = styled.div`
  padding: ${DesignTokens.spacing[5]};
  background: ${DesignTokens.colors.black};
  background-image: url(${pattern});
  background-size: 200%;
  img,
  h3,
  p {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  p:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 769px) {
    padding: 3rem 0;
    background-size: 50%;
    img {
      max-width: 50%;
    }
  }
`

const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    align-items: center;
    background-size: 50%;
    flex-direction: ${props => (props.even ? "row-reverse" : "row")};
    img {
      margin-bottom: 0;
      margin-right: ${DesignTokens.spacing[9]};
    }
  }
`

export default TextWithAsset
