import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextWithAsset from "../components/textWithAsset"
import Intro from "../components/intro"
import Footer from "../components/footer"

const CertificationsPage = ({ data: { page } }) => (
  <Layout>
    <SEO title={page.title} lang="it" />
    <Header>
      <h1>{page.title}</h1>
      <h2>{page.subtitle}</h2>
    </Header>
    <Intro title={page.title} text={page.intro} />
    {page.content.map(section => (
      <section key={section.id}>
        {section.model.apiKey === "text_with_asset" && (
          <TextWithAsset section={section} headingDowncase />
        )}
      </section>
    ))}
    <Footer />
  </Layout>
)

export default CertificationsPage

export const query = graphql`
  query CertificationsPageQuery {
    page: datoCmsCertificationsPage {
      intro
      title
      subtitle
      content {
        ... on DatoCmsTextWithAsset {
          id
          title
          text
          assets {
            originalId
            title
            url
            format
            size
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
